import { Injectable } from "@angular/core";
import { AlertController, NavController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { io } from "socket.io-client";

@Injectable({
    providedIn: 'root'
  })
export class SocketIOService {
    public socket;
    public pause = false;
    public serverStatus = new BehaviorSubject<boolean>(true);
    public firstRun = new BehaviorSubject<boolean>(true);
    constructor(private navCtrl: NavController){
    }

    public getSocket(): any{
        return this.socket;
    }

    public newConnect(token?: string){
        this.socket.disconnect()
        this.socket.connect();
    }

    public disconnect(){
        this.socket.disconnect()
    }

    public async createConnection(){
        this.socket = io("https://api.amazpep.com:3069", {
            query: {
                token: localStorage.getItem("ACCESS_TOKEN")
            },
            transports: ['websocket'],
            upgrade: false
        });

        return await this.addEndpoint();
    }

    private async addEndpoint(){
        return await new Promise((resolve, reject) => {
            this.socket.on("connect_error", (err) => {
                if(this.pause){
                   return; 
                }
                this.firstRun.next(false);
                resolve(false);
              });
    
            this.socket.on("connect", () => {
                this.firstRun.next(false);
                this.serverStatus.next(false);
                
                resolve(true);
            });
            
              this.socket.io.on("error", () => {
                if(this.pause){
                    return; 
                }

                this.firstRun.next(false);
                this.serverStatus.next(false);
                resolve(false);
            });
        });
    }
}