import { Component } from '@angular/core';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth/auth.service';
import { SocketIOService } from './services/socket-io/socket-io-client.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private socketService: SocketIOService,
    public socketIO: SocketIOService, 
    private authService: AuthService,
    private navCtrl: NavController,
    private platform: Platform,
    private menuCtrl: MenuController) {
      if(this.authService.isAuth.getValue() === true){
        this.socketService.createConnection();
        this.authService.autoLoginRequest();
        this.navCtrl.navigateRoot('dashboard');
      }
      this.platform.resume.subscribe(async () => {
        this.socketIO.pause = false;
        this.socketIO.newConnect();
      });
  
      this.platform.pause.subscribe(async () => {
        this.socketIO.pause = true;
      });
  
      this.initializeApp();
    }
  
    async initializeApp() {
      this.menuCtrl.enable(false, 'menu4');
    }
    
}
