import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardGuestService {  
    constructor(private navCtrl: NavController, public auth: AuthService, public router: Router) {}  
    canActivate(): boolean {
        return true;
    }
}