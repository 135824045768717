import { Injectable } from "@angular/core";
import { ServerModel } from "src/app/models/ServerModel";
import { UrlsModel } from "src/app/models/urls.model";
import { SocketIOService } from "../socket-io/socket-io-client.service";

@Injectable()
export class AdminService {
    constructor(private socketService: SocketIOService){
        
    }

    public async getOneHundredLogs(serverIp: string):  Promise<string[]>{
        var result: string[] = await new Promise(resolve => {      
            this.socketService.getSocket().emit('getOneHundredLogs', serverIp, 0, (hundredLogs: string[]) => {
                resolve(hundredLogs);
            })
        });

        return result;
    }

    public async getUrls(site: string) {
        var result: UrlsModel[] = await new Promise(resolve => {
            this.socketService.getSocket().emit('getUrls', site, (result: UrlsModel[]) =>{
                resolve(result);
            });
        });

        return result;
    }

    public async deleteUrl(url: UrlsModel) {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('deleteUrl', url, (result: boolean) =>{
                resolve(result);
            });
        })

        return result;
    }

    public async getAllServers(): Promise<ServerModel[]> {
        var result: ServerModel[] = await new Promise(resolve => {
            this.socketService.getSocket().emit('getAllServers', (result: ServerModel[]) =>{

                resolve(result);
            });
        })

        return result;
    }

    public async startUpOneServer(defaultIp: string, socketId: string) {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('startUpOneServer', defaultIp, socketId);
            resolve(true);
        })

        return result;
    }

    public async startUpOneShopServer(defaultIp: string, shop: string, socketId: string) {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('listinerStartUpOneSelectedShop',defaultIp, shop, socketId);
            resolve(true);
        })

        return result;
    }

    public async stopOneServer(defaultIp: string, socketId: string) {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('stopOneServer', defaultIp, socketId);
            resolve(true);
        })

        return result;
    }

    public async stopOneShopServer(defaultIp: string, code: string, socketId: string) {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('listinerStopOneSelectedShopInstance', defaultIp, code, socketId);
            resolve(true);
        })

        return result;
    }

    public async stopAllShopInstances(defaultIp: string, socketId: string){
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('stopAllShopInstances', defaultIp, socketId);
            resolve(true);
        })

        return result;
    }

    public async clearAllServers(){
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('clearAllServers', (response) => {
                resolve(true);
            });
        })

        return result;
    }

    public async cleanupUrls(site: string): Promise<number> {
        var result: number = await new Promise(resolve => {
            this.socketService.getSocket().emit('urlsCleanup', site, (result: number) =>{
                resolve(result);
            });
        })

        return result;
    }

    public async cleanupUrl(site: string, url?: UrlsModel): Promise<number> {
        var result: number = await new Promise(resolve => {
            this.socketService.getSocket().emit('urlCleanup', site, url, (result: number) =>{
                resolve(result);
            });
        })

        return result;
    }

    public async getAndRefreshAllServers(): Promise<ServerModel[]> {
        var result: ServerModel[] = await new Promise(resolve => {
            this.socketService.getSocket().emit('getAndRefreshAllServers', (result: ServerModel[]) =>{
                resolve(result);
            });
        })

        return result;
    }

    public async clearTerminal(serverIp: string) : Promise<any> {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('clearTerminal', serverIp, ( result: any) =>{
                resolve(result);
            });
        })

        return result;
    }

    public async generateToken() : Promise<any> {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('createNewToken', ( result: any) =>{
                resolve(result);
            });
        })

        return result;
    }
}