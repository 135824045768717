import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginViewComponent } from './auth/login/login.component';
import { AlertHelper } from './helpers/alert-helper';
import { AuthGuardAdminService } from './services/auth/auth-guard-admin.service';
import { AuthGuardGuestService } from './services/auth/auth-guard-guest.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AuthService } from './services/auth/auth.service';
import { LoadingService } from './services/loading/loading.service';
import { AdminService } from './services/server/server-admin.service';
import { SocketIOService } from './services/socket-io/socket-io-client.service';
import { ProfileAdminViewComponent } from './admin/profile-admin.component';
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [AppComponent, LoginViewComponent, ProfileAdminViewComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule, CommonModule ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SocketIOService,
    AuthService,
    AdminService,
    AuthGuardService,
    AuthGuardGuestService,
    AuthGuardAdminService,
    LoadingService,
    AlertHelper
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
