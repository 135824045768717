import { Injectable, OnInit } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable()
export class LoadingService {

    constructor(private loadingCtrl: LoadingController){

    }

    async showLoading(): Promise<HTMLIonLoadingElement> {
        const loading = await this.loadingCtrl.create({
          message: 'Loading...',
          cssClass: 'custom-loading'
        });
        
        loading.present();
        return loading;
    }
}