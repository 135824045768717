import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MongoDBRequest } from "src/app/models/auth/mongodb-response.model";
import { SocketIOService } from "../socket-io/socket-io-client.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { throwError } from 'rxjs';
import { AutologinResponse } from "src/app/models/auth/autologin.model";
import { NavController } from "@ionic/angular";
import { ChangePasswordRequest } from "src/app/models/auth/change-password.model";

@Injectable()
export class AuthService{
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
    private url = "https://api.amazpep.com:3069";
    public isAuth = new BehaviorSubject(localStorage.getItem('ACCESS_TOKEN')?.length > 0 ?? false);
    public isAdmin = new BehaviorSubject(false);
    public isPremium = new BehaviorSubject(0);
    constructor(private socketService: SocketIOService,private http: HttpClient, private navCtrl: NavController){}

    public async createLoginRequest(nickName: string, passWord: string) {
        var user: MongoDBRequest = {
            nickname: nickName,
            password: passWord
        };
        var result = await new Promise((resolve, reject) => {
            this.http.post<MongoDBRequest>(this.url + "/login", user, this.httpOptions)
            .subscribe(async data => {
                var checkUser = data as MongoDBRequest;
                
                if(checkUser?.nickname){
                    localStorage.setItem('ACCESS_TOKEN', checkUser.token);
                    localStorage.setItem('EXPIRES_IN', "2");
                    localStorage.setItem('UserDetails_USERNAME', user.nickname);

                    this.isAuth.next(true);
                    this.isPremium.next(checkUser.isPremium); 
                    
                    if(checkUser.isAdmin === true){
                        this.isAdmin.next(checkUser.isAdmin);
                    }
                    var result = await this.socketService.createConnection();

                    if(result === false){
                        this.isAuth.next(false);
                        localStorage.setItem('ACCESS_TOKEN', "");
                        resolve(false);
                    }
                    resolve(true);
                }else{
                    resolve(false);
                }
            }, error => {
                resolve(error.error.text);
            });
        });
        
        return result;
    }

    public async createSendCodeForgotRequest(email: string) {
        var user: MongoDBRequest = {
            nickname: "",
            password: "",
            email: email
        };
        var result = await new Promise((resolve, reject) => {
            this.http.post<string>(this.url + "/forgot-password", user, this.httpOptions)
            .subscribe(async data => {
                var checkUser = data as string;
                resolve(checkUser);
            }, error => {
                resolve(error.error.text);
            });
        });
        
        return result;
    }

    /*public async createSendCodechangePassword() {
        var result = await new Promise((resolve, reject) => {
            this.http.post<string>(this.url + "/change-password-request", this.httpOptions)
            .subscribe(async data => {
                var checkUser = data as string;
                resolve(checkUser);
            }, error => {
                resolve(error.error.text);
            });
        });
        
        return result;
    }*/

    public async createSendCodechangePassword(): Promise<boolean | string>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('change-password-request', (message: boolean | string) =>{
                resolve(message as boolean | string);
            });
        });

        return result as boolean | string;
    }

    public async createChangePasswordRequest(code: string, password: string) {
        var user: ChangePasswordRequest = {
            password: password,
            code: code
        };
        var result = await new Promise((resolve, reject) => {
            this.http.post<string>(this.url + "/change-password", user, this.httpOptions)
            .subscribe(async data => {
                var checkUser = data as string;
                resolve(checkUser);
            }, error => {
                resolve(error.error.text);
            });
        });
        
        return result;
    }

    public async createRegisterRequest(user: MongoDBRequest){
        var result = await new Promise((resolve, reject) => {
            this.http.post<MongoDBRequest>(this.url + "/register", user, this.httpOptions)
            .subscribe(async data => {
                var checkUser = data as MongoDBRequest;
                //console.log(data);
                
                if(checkUser?.nickname){
                    localStorage.setItem('ACCESS_TOKEN', checkUser.token);
                    localStorage.setItem('EXPIRES_IN', "2");
                    this.isAuth.next(true);
                    this.isPremium.next(checkUser.isPremium); 
                    
                    if(checkUser.isAdmin === true){
                        this.isAdmin.next(checkUser.isAdmin);
                    }
                    var result = await this.socketService.createConnection();

                    if(result === false){
                        this.isAuth.next(false);
                        localStorage.setItem('ACCESS_TOKEN', "");
                        resolve(false);
                    }
                    resolve(true);
                }else{
                    resolve(false);
                }
            }, error => {
                resolve(error.error.text);
            });
        });

        return result;
    }

    public async logout(): Promise<boolean>{
        localStorage.clear();
        this.isAdmin.next(false);
        this.isAuth.next(false);
        this.socketService.disconnect();
        this.navCtrl.navigateRoot('login');
        return true;
    }

    public async test(): Promise<boolean>{
        var result:boolean = await new Promise(resolve => {
            this.socketService.getSocket().emit('test');
            resolve(true);
        });

        return result;
    }

    public async test2(): Promise<boolean>{
        var result:boolean = await new Promise(resolve => {
            this.socketService.getSocket().emit('test2');
            resolve(true);
        });

        return result;
    }

    public async getNewAutoLoginData(): Promise<AutologinResponse>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('autoLogin', (message: AutologinResponse) =>{
                resolve(message as AutologinResponse);
            });
        });

        return result as AutologinResponse;
    }

    public async autoLoginRequest(){
        if(this.isAuth.getValue()){
            //console.log("Autologin");
            this.getNewAutoLoginData().then(response => {
                this.isAdmin.next(response.isAdmin);
                this.isPremium.next(response.isPremium ?? 0);
            });
        }
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
          console.error('An error occurred:', error.error);
        } else {
          console.error(
            `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Something bad happened; please try again later.'));
      }
}