import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AlertController, IonSlides, ModalController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ServerModel } from 'src/app/models/ServerModel';
import { UrlsModel } from '../models/urls.model';
import { AuthService } from '../services/auth/auth.service';
import { LoadingService } from '../services/loading/loading.service';
import { AdminService } from '../services/server/server-admin.service';
import { SocketIOService } from '../services/socket-io/socket-io-client.service';
@Component({
  selector: 'profile-admin',
  templateUrl: 'profile-admin.component.html',
  styleUrls: ['profile-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileAdminViewComponent implements OnInit {
  public terminalResultArrayLogs = new BehaviorSubject<string>("");
  public urls = new BehaviorSubject<UrlsModel[]>([]);
  public tabs: string[] = [];
  public servers = new BehaviorSubject<ServerModel[]>([]);
  public tabIndex: number;
  public stringCurrentSite: string = 'amazon';
  public currentSelectedServerIp: string = ''
  public currentSelectedServer: ServerModel = {
     ip: "",
     socketId: "",
     isInUse: false,
     maxUrls: 0,
     available: false,
     urls: [],
     vpnIp:  "",
     isAmazon:  false,
     isMultiShop:  false,
     availibleShopsAndLimits: [],
     activeInstances: []
  }
  public currentSelectedShop: string = ''
  public newtoken: string = ''
  public currentSelectedShopInstanceCode: string = ''
  public newUrl: UrlsModel = {
    page: 1,
    productIndex: 0,
    url: "",
    checked: false,
    useBy: "",
    categoryTag: "",
    site: "",
    maxPage: 0,
    maxProducts: 0,
    rhNumber: "",
    referer: "",
    name: "",
    startFrom: 0,
    category: 0,
    used: 0,
    processedProducts: 0,
    code: ""
  }
  public logsAutoRefresh: boolean = false;

  constructor(private navCtrl: NavController, 
    private adminService: AdminService,
    private socketService: SocketIOService,
    private authService: AuthService,
    private alertController: AlertController,
    public modalCtrl: ModalController,
    private loadingService: LoadingService) {
    this.tabs = [];
    this.tabIndex = 0;
    this.socketService.getSocket().on("terminalResponse", (serverIp: string, response: string) => {
      if(serverIp == this.currentSelectedServerIp && this.logsAutoRefresh){
        var valueArr = this.terminalResultArrayLogs.getValue();
        response += valueArr;
        this.terminalResultArrayLogs.next(response);
      }
    });

    this.socketService.getSocket().on("shopInstanceDataHasChanged", (serverIp: string, server: ServerModel) => {
      if(serverIp == this.currentSelectedServerIp){
        var servers = this.servers.getValue();
        var findedServerIndex = servers.findIndex(item => item.ip == serverIp);
        if(findedServerIndex != -1){
          servers[findedServerIndex] = server;
          this.servers.next(servers);
          this.currentSelectedServer = server;
        }
      }
    });

    this.socketService.getSocket().on("refershAdminServers", (server: ServerModel) => {
      var findedServerIndex = servers.findIndex(item => item.ip == server.ip);
      if(findedServerIndex == undefined){
        var servers = this.servers.getValue();
        servers.push(server);
        this.servers.next(servers);
      }else{
        var servers = this.servers.getValue();
        servers[findedServerIndex] = server;
        this.servers.next(servers);
      }
    });

    this.socketService.getSocket().on("clearTerminal", (response: string) => {
        this.terminalResultArrayLogs.next("");
    });
    
    if(this.authService.isAdmin){
      this.tabs.push("Server");
      this.tabs.push("Urls");
      this.tabs.push("Healthy");
      this.tabs.push("Management");
    }
  }

  public async getOneHundredLogs(){
    var hundredLogs = await this.adminService.getOneHundredLogs(this.currentSelectedServerIp);
    var valueArr = this.terminalResultArrayLogs.getValue();
    var newValue = valueArr += hundredLogs.join("");
    this.terminalResultArrayLogs.next(newValue);
  }

  ngOnDestroy() {
    this.socketService.getSocket().off("terminalResponse");
    this.socketService.getSocket().off("shopInstanceDataHasChanged");
    this.socketService.getSocket().off("refershAdminServers");
  }

  ngOnInit() {
    this.initPanel();
  }

  public segmentChange(event) {
    const newtab = event._value;
  }

  public selectTab(slide, index: number) {
    slide.slideTo(index);
    slide.getActiveIndex().then(index => {
      this.tabIndex = index;
    });
  }

  async initPanel(){
    var urlsArray = await this.adminService.getUrls(this.stringCurrentSite);
    var servers = await this.adminService.getAllServers();

    this.currentSelectedServerIp = servers?.[0]?.ip ?? "";
    this.servers.next(servers);
    this.urls.next(urlsArray); 
    this.refreshCurrentSelectedServer();
    await this.getOneHundredLogs();
  }

  public async handleChangeSite(event){
    this.stringCurrentSite = event.target.value;
    var urlsArray = await this.adminService.getUrls(this.stringCurrentSite);
    this.urls.next(urlsArray);
  }

  public async handleChangeLogsServer(event){
    this.currentSelectedServerIp = event.target.value;
    this.refreshCurrentSelectedServer();
    this.terminalResultArrayLogs.next("");
    this.getOneHundredLogs();
  } 

  public refreshCurrentSelectedServer(){
    this.currentSelectedServer = this.servers.getValue().find(x => x.ip == this.currentSelectedServerIp);
    this.currentSelectedShop = "";
    this.currentSelectedShopInstanceCode = "";
  }
  
  public async handleChangeShop(event){
    this.currentSelectedShop = event.target.value;
  }

  public async handleChangeShopInstance(event){
    this.currentSelectedShopInstanceCode = event.target.value;
  }

  public async clearLog(){
    var result = this.adminService.clearTerminal(this.currentSelectedServerIp);
    this.terminalResultArrayLogs.next("");
  }

  public async getAndRefreshAllServers(){
    var result = await this.adminService.getAndRefreshAllServers();
    this.servers.next([]);
    this.servers.next(result);
  }

  public async startUpOneServer(defaultIp: string, socketId: string){
    await this.adminService.startUpOneServer(defaultIp, socketId);
  }

  public async generateToken(){
    this.newtoken = "";
    this.newtoken = await this.adminService.generateToken();
  }

  public async startUpOneShopServer(defaultIp: string, shop: string, socketId: string){
    await this.adminService.startUpOneShopServer(defaultIp, shop, socketId);
  }

  public async startUpOneServerIp(){
    await this.getAndRefreshAllServers();
    var selectedServer = this.servers.getValue().find(x => x.ip == this.currentSelectedServerIp);
    if(selectedServer != null && selectedServer != undefined){
      var socketId = selectedServer?.socketId;
      if(socketId != null && socketId != undefined){
        if(selectedServer.isMultiShop){
          if(this.currentSelectedShop != ""){
            await this.adminService.startUpOneShopServer(this.currentSelectedServerIp, this.currentSelectedShop, socketId);
          }
        }
        else[
          this.startUpOneServer(this.currentSelectedServerIp, socketId)
        ]
      }
    }
  }

  public async stopOneServer(defaultIp: string, socketId: string){
    await this.adminService.stopOneServer(defaultIp,socketId);
  }

  public async stopOneShopServer(defaultIp: string, code: string , socketId: string){
    await this.adminService.stopOneShopServer(defaultIp, code, socketId);
  }

  public stopLogsAutoRefresh(){
    this.logsAutoRefresh = !this.logsAutoRefresh;
  }

  public async stopAllShopInstances(){
    if(selectedServer.isMultiShop){
      await this.getAndRefreshAllServers();
      var selectedServer = this.servers.getValue().find(x => x.ip == this.currentSelectedServerIp);
      if(selectedServer != null && selectedServer != undefined){
        var socketId = selectedServer?.socketId;
        if(socketId != null && socketId != undefined){
          await this.adminService.stopAllShopInstances(this.currentSelectedServerIp, socketId)
        }
      }
    }
  }

  public async stopOneServerIp(){
    await this.getAndRefreshAllServers();
    var selectedServer = this.servers.getValue().find(x => x.ip == this.currentSelectedServerIp);
    if(selectedServer != null && selectedServer != undefined){
      var socketId = selectedServer?.socketId;
      if(socketId != null && socketId != undefined){
        if(selectedServer.isMultiShop){
          if(this.currentSelectedShopInstanceCode != ""){
            await this.adminService.stopOneShopServer(this.currentSelectedServerIp, this.currentSelectedShopInstanceCode, socketId);
          }
        }
        else{
          await this.adminService.stopOneServer(this.currentSelectedServerIp, socketId);
        }
      }
    }
  }

  async clearAllServers(){
    await this.adminService.clearAllServers();
    await this.getAndRefreshAllServers();
  }

  public async cleanupUrls(){
    var loading = await this.loadingService.showLoading();
    
    await this.adminService.cleanupUrls(this.stringCurrentSite);
    loading.dismiss​();
    var urlsArray = await this.adminService.getUrls(this.stringCurrentSite);
    this.urls.next(urlsArray);
  }

  public async cleanupUrl(url?: UrlsModel){
    var loading = await this.loadingService.showLoading();
    if(url != undefined){
      await this.adminService.cleanupUrl(this.stringCurrentSite, url);
    }
    loading.dismiss​();

    var urlsArray = await this.adminService.getUrls(this.stringCurrentSite);
    this.urls.next(urlsArray);
  }

  public async refreshLog(){
    this.terminalResultArrayLogs.next("");
    await this.getOneHundredLogs();
  }

  goBack()  {
    this.navCtrl.back();
  }

  async inputEmpty(info?: string) {
    const alert = await this.alertController.create({
      header: 'Empty input!',
      message: 'Fill all inputs.',
      buttons: ['Ok'],
    });

    await alert.present();

    var urlsArray = await this.adminService.getUrls(this.stringCurrentSite);
    this.urls.next(urlsArray);
  }

  async stopOneServerConfirm(defaultIp: string, socketId: string){
    const alert = await this.alertController.create({
      header: 'Stop All',
      buttons: [{
        text: 'Yes',
        handler: async () => {
          const closeModal: string = "Modal Closed";
          await this.stopOneServer(defaultIp, socketId);
        }
      },{
        text: 'No',
        handler: async () => {
          const closeModal: string = "Modal Closed";
        }
      }],
    });

    await alert.present();
  }

  async stopOneShopServerConfirm(defaultIp: string, code: string, socketId: string){
    const alert = await this.alertController.create({
      header: 'Stop one shop server',
      buttons: [{
        text: 'Yes',
        handler: async () => {
          const closeModal: string = "Modal Closed";
          await this.stopOneShopServer(defaultIp, code, socketId);
        }
      },{
        text: 'No',
        handler: async () => {
          const closeModal: string = "Modal Closed";
        }
      }],
    });

    await alert.present();
  }

  async stopAllShopsInstancesConfirm(){
    const alert = await this.alertController.create({
      header: 'Stop all shop instances',
      buttons: [{
        text: 'Yes',
        handler: async () => {
          const closeModal: string = "Modal Closed";
          await this.stopAllShopInstances();
        }
      },{
        text: 'No',
        handler: async () => {
          const closeModal: string = "Modal Closed";
        }
      }],
    });

    await alert.present();
  }

  async stopOneServerIpConfirm(){
    const alert = await this.alertController.create({
      header: 'Stop server by ip',
      buttons: [{
        text: 'Yes',
        handler: async () => {
          const closeModal: string = "Modal Closed";
          await this.stopOneServerIp();
        }
      },{
        text: 'No',
        handler: async () => {
          const closeModal: string = "Modal Closed";
        }
      }],
    });

    await alert.present();
  }

  async clearTerminaklConfirm(){
    const alert = await this.alertController.create({
      header: 'Clear terminal',
      buttons: [{
        text: 'Yes',
        handler: async () => {
          const closeModal: string = "Modal Closed";
          await this.clearLog();
        }
      },{
        text: 'No',
        handler: async () => {
          const closeModal: string = "Modal Closed";
        }
      }],
    });

    await alert.present();
  }

  async cleanUpConfirmUrls(){
    const alert = await this.alertController.create({
      header: 'Clean Up',
      buttons: [{
        text: 'Yes',
        handler: async () => {
          const closeModal: string = "Modal Closed";
          await this.cleanupUrls();
        }
      },{
        text: 'No',
        handler: async () => {
          const closeModal: string = "Modal Closed";
        }
      }],
    });

    await alert.present();
  }

  async cleanUpConfirmUrl(url?: UrlsModel){
    const alert = await this.alertController.create({
      header: 'Clean Up',
      buttons: [{
        text: 'Yes',
        handler: async () => {
          const closeModal: string = "Modal Closed";
          await this.cleanupUrl(url);
        }
      },{
        text: 'No',
        handler: async () => {
          const closeModal: string = "Modal Closed";
        }
      }],
    });

    await alert.present();
  }
}