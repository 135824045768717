import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardAdminService {  
    constructor(private navCtrl: NavController, public auth: AuthService, public router: Router) {}  

    canActivate(): boolean {
        if (!this.auth.isAuth.getValue()) {
            this.navCtrl.navigateRoot(['login']);
            return false;
        }

        if(!this.auth.isAdmin.getValue()){
            this.navCtrl.navigateRoot(['dashboard']);
            return false;
        }
        return true;
    }
}