import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertHelper } from '../../helpers/alert-helper'

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginViewComponent implements OnInit {

  plt;
  public nickname: string;
  public password: string;
  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();
  constructor(private navCtrl: NavController, 
    private platform: Platform, 
    private authService: AuthService,
    private alertController: AlertController, 
    private alertHelper: AlertHelper) {

    this.plt = localStorage.getItem('platform');
  }

  ngOnInit() {
    this.isSending$ = this.isSending.asObservable();
    if(this.authService.isAuth.getValue() == true){
      this.navCtrl.navigateRoot('dashboard');
    }
  }

  async sendLoginRequest(){
    if(!this.checkInputs()){
      return;
    }
    this.isSending.next(!this.isSending.getValue());
    var result = await this.authService.createLoginRequest(this.nickname, this.password);
    
    if(result === true){
      this.navCtrl.pop();
      this.navCtrl.navigateRoot('dashboard');
    }else if(result === false){
      this.alertHelper.presentDefaultAlert("Something is wrong", "Check all data again", ["Register again"]);
    }
    else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }
    this.isSending.next(!this.isSending.getValue());
  }

  goRegister()  {
    this.navCtrl.navigateForward('register');
  }

  async handleButtonClick() {
    this.alertHelper.presentDefaultAlert("Something broken", "Your username or password seem to be broken :(", ["Register again"]);
  }

  forgot(){
    this.navCtrl.navigateForward('password-forgot');
  }

  checkInputs(){

    if(!this.nickname){
      this.alertHelper.presentDefaultAlert("Insufficient data", "Nick name is required!", ["Try again"]);
      return false;
    }
    
    if(!this.password){
      this.alertHelper.presentDefaultAlert("Insufficient data", "Password is required!", ["Try again"]);
      return false;
  }

    return true;
  }
}